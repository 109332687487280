<template>
  <div class="contain">
      <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :model="search"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus" label-width="64px">
            <!-- @change="searchFun" -->
          <el-date-picker
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 废弃 -->
        <!-- <el-form-item label="巡检设备" prop="equipmentStatus" style="margin-left: 28px;">
          <el-select
            clearable
            size="mini"
            style="width:170px;"
            v-model="search.isPlan"
            placeholder="请选择巡检设备"
            @change="inquire"
          >
            <el-option
              v-for="item in equipment"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="任务情况" prop="equipmentStatus" style="margin-left: 28px;">
          <el-select
            clearable
            size="mini"
            style="width:170px;"
            v-model="search.isPlan"
            placeholder="请选择任务情况"
            
          >
          <!-- @change="inquire" -->
            <el-option
              v-for="item in mission"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="equipmentStatus">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="search.isOpen"
            placeholder="请选择状态"
            
          >
          <!-- @change="inquire" -->
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus" style="margin-left: 14px;">
          <el-input
          size="mini"
            placeholder="请输入巡检区域/巡线员姓名 "
            v-model="search.planName"
            style="width:340px"
          >
          </el-input>
        </el-form-item>
       <el-form-item label="" prop="" label-width="0">
        <el-button
          type="primary"
          size="mini"
          @click="searchFun"
          style="margin-left:20px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="reset"
          type="warning"
          @click="reset"
          >重置</el-button
        >
        </el-form-item>
      </el-form>
    </div>
    <div class="modalityCenter">
     <!-- <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="goPath('/home/inspePlanAdds')"
          >添加</el-button> -->
             <el-button
          size="mini"
          type="text"
          class="addButton"
          v-if="$anthButtons.getButtonAuth('addPlan')"
          @click="addItem(1)"
          >添加</el-button>
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      class="table-no-search"
      @selection-change="handleSelectionChange"
      height="calc(100vh - 356px)"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
    <el-table-column
      type="index"
      width="80px"
      align="left"
      label="序号">
    </el-table-column>
    <el-table-column align="left" prop="networkName" label="巡检区域">
      <template slot-scope="{row}">
          <div class="examine" style="color:#2C9EF7"  @click="goPath('/home/inspePlanAdds',row.networkId,row.executorPeopleId,row.id,true,row)">{{ row.networkName }}</div>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="coverageName" label="巡检图层" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="left" width="100" prop="checkingPoint" label="巡检点数">
      </el-table-column>
       <el-table-column align="center" prop="lineLength" label="管线长度（km）">
       <template slot-scope="{row}">
          <div class="examine" style="color:#2C9EF7"  @click="seeKm(row)">{{(row.jsL&&row.jsL!=-1)?row.jsL:(row.jsL == 0 ? 0 :'查看')}}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="left" prop="lineLength" label="管线长度（km）">
        <template slot-scope="scope">
          {{ scope.row.lineLength==0?0:(scope.row.lineLength / 1000).toFixed(3) || 0}}
        </template>
      </el-table-column> -->
      
      <el-table-column align="left" width="90" prop="equipmentCount" label="打卡点数">
        <template slot-scope="scope">
          {{ scope.row.equipmentCount==0?0:scope.row.equipmentCount || 0}}
        </template>
      </el-table-column>
      <el-table-column align="left" width="100" prop="executorPeopleName" label="巡线员">
      </el-table-column>
      <el-table-column align="left" prop="createTime" label="创建时间">
      </el-table-column>
      <el-table-column align="center" width="90" prop="isPlan" label="任务情况">
      </el-table-column>
      <el-table-column align="center" width="70" prop="isOpen" label="状态">
      </el-table-column>
      <el-table-column label="操作" align="left" width="257px">
        <template slot-scope="{ row }">
          <el-button type="primary" size="small" 
          v-if="$anthButtons.getButtonAuth('editPlan')"
          @click="addItem(row)">编辑</el-button>
          <el-button
            @click.native.prevent="clickStatus(2, row)"
            type="text"
            size="small"
            style="border: 1px solid #1082ff;
                        color: #1082ff;
                        padding: 0 15px;
                        height: 30px;
                        margin: 0 11px 10px;"
            v-if="row.isOpen == '已开启' && $anthButtons.getButtonAuth('offPlan')"
            >关闭</el-button
          >
          <el-button
            @click.native.prevent="clickStatus(1, row)"
            type="success"
            size="small"
            v-if="row.isOpen == '已关闭' && $anthButtons.getButtonAuth('onPlan')"
            >开启</el-button
          >
          <el-button
            @click.native.prevent="changeStatus(3, row.id)"
            type="danger"
            v-if="row.isPlan == '未安排' && row.isOpen == '已关闭'  && $anthButtons.getButtonAuth('deletePlan')" 
            size="small">删除</el-button >
          
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: left">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="search.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    </div>
   <el-dialog
        :title="title"
        :visible.sync="showDialogVisible"
        :show-close="true"
        :close-on-click-modal="false"
        width="460px"
        @close="close"
        >
         <el-form
          ref="formAdd"
          :model="form"
          :rules="rules"
          label-width="80px"
          class="formBox"
           size="small"
        >
          <el-form-item label="巡线区域" prop="networkId">
            <el-select
            style="width:100%"
              v-model="form.networkId"
              placeholder="请选择"
              class="widthes"
            >
              <el-option
                v-for="item in networkList"
                :key="item.id"
                :label="item.networkName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="巡线员" prop="executorPeopleId">
            <el-select
              v-model="form.executorPeopleId"
              placeholder="请选择"
              class="widthes"
              style="width:100%"
            >
              <el-option
                v-for="item in excutorDownArray"
                :key="item.id"
                :label="item.username+'('+item.mobile+')'"
                :value="item.id.toString()"
              >
              </el-option>
            </el-select>
          </el-form-item>
         
        </el-form>
         <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="showDialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="onSubmit()">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  inspectionPlanList,
  delset,
  operation,
  getNetwork,
  projectAdd,
  ChangePlan
} from "../../RequestPort/Inspection/inspePlan.js";
import { getUserList,getLength } from "@/RequestPort/maintenance/task";
import { dicByType } from "../../RequestPort/maintenance";
import { queryFeatrue } from '../Gis/apis/geo.js';
import { formatLocation } from '../Gis/utils/geoTool.js';

export default {
  name: "inspePlan",
  data() {
    return {
      showDialogVisible:false,
      excutorDownArray: [],
      networkList:[],
      // 任务情况
      mission:[
        { label: "未安排", value: 2 },
        { label: "已安排", value: 1 },
      ],
      // 状态
      stateList:[
        { label: "已开启", value: 1 },
        { label: "已关闭", value: 2 },
      ],
      // monthArray: [],
      DataPicker: [],
      search: {
        current: 1,
        planName: "",
        size: 100,
      },
      // status: "",
      // 列表数据
      tableData: [],
      // searchText: "",
      // 总条数
      total: 0,
      choseArr: [],
     
      form: {
        executorPeopleId: "",
        networkId: "",
      },
      // 设备
      equipment:[],
      rules:{
        networkId: [{ required: true, message: "请选择巡线区域", trigger: "change" }],
        executorPeopleId: [
          { required: true, message: "请选择巡线员", trigger: "change" },
        ],
      },
      title:'添加',
      loading:null
    };
  },
  mounted() {
    this.getOption();
    this.getList();
    
   //设备类型
    dicByType({parentCode:'sblx',level:3}).then(res => {
        if(res.code == 200) {
            this.equipment = res.data[0].children
        } else {
            this.$message.error(res.msg)
        }
    })
  },
  methods: {
    seeKm(row){
      getLength({id:row.networkId}).then(res => {
        if(res.code == 200) {
          row.jsL = res.data
        }
      })
      // let coverage=row.coverage
      // let network=row.location
      // let pos = []
      // network.forEach(element => {
      //   pos.push(element[0] + ' ' + element[1])
      // })
      // pos.push(pos[0])
      // let posStr = pos.join(',')
      // let groupLayer = this.$store.getters.groupLayer
      // let group = []
      // if (groupLayer && groupLayer.length > 0) {
      //   groupLayer.forEach(item => {
      //     group.push(item.id)
      //   })
      // }
      
      // let layersVue1 = coverage
      // let params = {
      //   SERVICE: 'wfs',
      //   VERSION: '1.0.0',
      //   REQUEST: 'GetFeature',
      //   typeName: 'linqing:view_all_line_q',
      //   outputFormat: 'application/json',
      //   viewparams: `netid:${row.networkId}`,
      //   CQL_FILTER:
      //       "coverage in (" + layersVue1 + ") and INTERSECTS(location,POLYGON((" + posStr + ")))"
      //   }
      //   if (!layersVue1) {
      //    params.CQL_FILTER = '1=2'
      //   }  
      //    const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });      
      // queryFeatrue(params).then(res => {//调取管线
      //    loading.close();
      //   // this.total = res.totalFeatures
      //   let f = res.features
      //   let line=[]
      //     f.forEach(item => {
      //       let location=formatLocation(item.properties.location_str,'LineString')      
      //       console.log(location)
            
      //            for(let i=1;i<location.length;i++){
                 
      //              let sub=[Number(location[i-1][0]),Number(location[i-1][1])]
      //              let sub1=[Number(location[i][0]),Number(location[i][1])]
                
      //             line.push([sub1,sub])
      //            }
                 
          
          
      //   })


      //     let py=network
      //    // py.push(py[0])//首尾相接
      //     let allLength=0
      //      line.forEach(item => {//计算长度
      //         var line1 = turf.lineString(item);
      //         var line2 = turf.lineString(py);
      //         var intersects = turf.lineIntersect(line1, line2);  
      //         if(intersects.features.length<1){//没相交是面内
      //             let pn = turf.polygon([py]);                 
      //             let isHave=turf.booleanContains(pn, line1);
      //             if(isHave){                   
      //                 let length = turf.length(line1, {units: 'kilometers'});
      //                 allLength=allLength+length
      //             }                
      //         }else if(intersects.features.length>0){//一条线相交了两次及以上
      //           var line = turf.lineString(item);
      //           var splitter = turf.lineString(py);
      //           var splitline = turf.lineSplit(line, splitter);     
      //           splitline.features.forEach(fea => {     
      //             let feaLine=  fea.geometry.coordinates
      //             let pn = turf.polygon([py]);
      //             let pl = turf.lineString(feaLine);
      //             let isHave=turf.booleanContains(pn, pl);
      //             if(isHave){
      //               let l=turf.lineString(feaLine)
      //               let length = turf.length(l, {units: 'kilometers'});
      //               allLength=allLength+length
      //             }
      //           }); 
      //         }
      //     })
      
      //    allLength>0?row.jsL=allLength.toFixed(3):row.jsL='0'
      // })

    },
    getOption(){
      getNetwork({}).then(res=>{
        this.networkList = res.data
      })
      // 添加巡线员
      getUserList({type:1}).then((e) => {
      this.excutorDownArray = e.data;
    });
    },
    onSubmit(){
      this.$refs.formAdd.validate((valid) => {
          if (!valid) return false
          projectAdd(this.form).then(res=>{
            this.$message.success(res.msg);
            this.showDialogVisible = false;
            this.getList();
          })
      })
    },
    addItem(row){
      if(row!=1){
        this.title = '编辑'
        this.form.id = row.id;
        this.form.networkId = row.networkId
        this.form.executorPeopleId = row.executorPeopleId
      }else{
        this.title = '添加'
      }
      this.showDialogVisible = true
    },
    close(){
      this.form = {
        executorPeopleId: "",
        networkId: "",
      }
      this.$refs.formAdd.resetFields()
    },
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
    /**
     * 触发下拉进行查询
     */
    inquire(){
      this.searchFun()
    },
    /**
     * 编辑开启和关闭状态
     */
    clickStatus(val,row){
      let p = {
        id:row.id,
        isOpen:val
      }
      operation(p).then((e) => {
        this.getList();
        this.$message.success("操作成功");
      });
    },
    
    /**
     * 查询事件
     */
    searchFun() {
      this.search.current = 1;
      if (this.DataPicker && this.DataPicker.length > 0) {
        this.search.startDate = this.DataPicker[0];
        this.search.endDate = this.DataPicker[1];
      } else {
        this.search.startDate = null;
        this.search.endDate = null;
      }
      this.getList();
    },
    /**
     * 重置
     */
    reset() {
      this.search = {
        current: 1,
        planName: "",
        size: 100,
      };
      this.DataPicker = [];
      this.getList();
    },
   
    /**
     * 添加计划//跳转
     */
    goPath(path, id,executorPeopleId,planId,strat,row) {
      let sql = ''
      let pol = ''
      // console.log(row,"走的这个",row.networkId)
      if(row.coverage){
        // let coverages = []
        // coverages = row.coverage.split(',')
        // this.$store.commit('setLayerTree',coverages )
          // sql=sql+' and coverage in ('+ coverage+')'
          // sql=" ((tableName = 'patrol_lines'  and coverage in ("+ row.coverage+") and  network_id = ("+ row.coverage+")) or (tableName = 'tb_basic_pipe'  and coverage in ("+ row.coverage+")))"
          sql="((tableName = 'patrol_clock'   and  network_id in ("+ row.networkId+")) or (tableName <> 'patrol_clock' and tableName <> 'tb_basic_pipe' and tableName <> 'patrol_lines'  and  coverage in ("+ row.coverage+")))"
        } else {
          sql=" coverage in ('clock' )"
        }
        
        if(row.location){    
            let path=row.location
            if(path&&path.length>0){
                let newp=[]
                path.forEach(el => {
                  newp.push(el.join(' '))
                });
                newp=newp.join(',')
                pol=pol+'(('+newp+'))'
            }
            sql=sql+' and INTERSECTS(location,MULTIPOLYGON('+pol+'))'
        }
        if( row.networkId) {
          sql=sql+" or (tableName = 'patrol_clock' and network_id = "+ row.networkId+") "
        }
        this.$store.commit('setLayersql',sql )
        this.$store.commit('setLayerRow',row )
      // this.$router.push({ path: path, query: { id: id,peopleId:executorPeopleId,planId:planId, inquire:strat,} })
      let chakan = this.$router.resolve({
        path: path,      //跳转目标窗口的地址
        query: { id: id,peopleId:executorPeopleId,planId:planId, inquire:strat,setLayerRow:JSON.stringify(row),setLayersql:sql}
      })
      window.open(chakan.href);
    },
    /**
     * 删除
     */
    changeStatus(type, id) {
      if (id) {
        this.$confirm("是否删除此条数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal:false,
          type: "warning",
        }).then(() => {
          delset([id]).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.getList();
            }
          });
        });
        return;
      }
     
    },
    /**
     * 查询列表数据
     */
    getList() {
      if(this.tableData.length == 1 && (this.search.current != 1)) {
        this.search.current -= 1 
      }
      inspectionPlanList(this.search).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          records.forEach(item => {
            item.jsL='-1'
          });
          this.tableData = records;
          this.total = total;
        }
      });
    },
    handleSizeChange(e) {
      this.search.size = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.search.current = e;
      this.getList();
    },
    handleSelectionChange(e) {
      this.choseArr = e;
    },
  },
};
</script>

<style lang="less" scoped>
.contain{
  background-color:transparent;
  padding: 0;
}

// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // // 面包屑
  // .crumbs{
  //   padding: 10px 15px 20px;
  //   span{
  //     color: #3069E1;
  //   }
  // }
  // .el-form-item{
  //   margin-left: 20px;
  // }
  // .el-form-item__label{
  //   text-align: justify;
  // }
  // ::v-deep.el-input__inner{
  //   height: 36px;
  //   line-height: 36px;
  // }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 10px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
    float:right;
    border: 1px solid #1082ff;
    color: #1082ff;
    padding: 0 15px;
    height: 30px;
    margin: 0 11px 10px;
  }
  // ::v-deep.warning-row{
  //   background: #F9FAFC;
  //    .el-table__cell>.cell {
  //   font-size: 14px;
  //   color: #0C235B;
    
  //    }
  // }
// ::v-deep.success-row{ 
//   background: #ffffff;
//   .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
//   }
// }
}
// ::v-deep.el-table th.el-table__cell>.cell{
//   font-size: 15px;
// }

// 搜索
.searchForm{
  display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 区域鼠标移上事件
.examine{
 cursor: pointer;
}
// 分页按钮
.el-pagination{
      margin: 15px 0 5px;
}
.view-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.view-table th {
  // background-color: #f5faff;
  font-weight: 400;
  text-align: left;
  width: 180px;
}
.view-table td,
.view-table th {
  border: 1px solid #d1e8fe;
  line-height: 40px;
  padding: 0 15px;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  .left {
    width: 30%;
    margin: 10px 0;
  }
  .right {
    width: 35%;
  }
}
.el-input-group,
.el-input {
  width: initial;
}
.reset {
  margin-left: 10px;
}
.block {
  text-align: right;
}
</style>
